@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?v1aicr');
    src:    url('../fonts/icomoon.eot?v1aicr#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?v1aicr') format('truetype'),
        url('../fonts/icomoon.woff?v1aicr') format('woff'),
        url('../fonts/icomoon.svg?v1aicr#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-mazetto:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mazetto{
    &.icon-rss:before {content: "\ea9b";}
    &.icon-rss2:before {
        content: "\ea9c";
    }
    &.icon-seguro_camera:before {
        content: "\e91b";
    }
    &.icon-seguro_carro-blindado:before {
        content: "\e91c";
    }
    &.icon-seguro_equipamentos:before {
        content: "\e91d";
    }
    &.icon-seguro_frota:before {
        content: "\e91e";
    }
    &.icon-seguro_moto:before {
        content: "\e91f";
    }
    &.icon-seguro_portaveis:before {
        content: "\e920";
    }
    &.icon-seguro_previdencia-privada:before {
        content: "\e921";
    }
    &.icon-seguro_residencial:before {
        content: "\e922";
    }
    &.icon-seguro_riscos-engenharia:before {
        content: "\e923";
    }
    &.icon-seguro_saude:before {
        content: "\e924";
    }
    &.icon-seguro_viagem:before {
        content: "\e925";
    }
    &.icon-seguro_vida:before {
        content: "\e926";
    }
    &.icon-men:before {
        content: "\e919";
    }
    &.icon-woman:before {
        content: "\e91a";
    }
    &.icon-ok:before {
        content: "\e917";
    }
    &.icon-wrong:before {
        content: "\e918";
    }
    &.icon-duvidas:before {
        content: "\e912";
    }
    &.icon-produtos:before {
        content: "\e913";
    }
    &.icon-seguradoras:before {
        content: "\e914";
    }
    &.icon-seguros:before {
        content: "\e915";
    }
    &.icon-sobre:before {
        content: "\e916";
    }
    &.icon-facebook:before {
        content: "\e90d";
    }
    &.icon-google-plus:before {
        content: "\e90e";
    }
    &.icon-instagram:before {
        content: "\e90f";
    }
    &.icon-linkedin:before {
        content: "\e910";
    }
    &.icon-twitter:before {
        content: "\e911";
    }
    &.icon-atendimento_m:before {
        content: "\e900";
    }
    &.icon-atendimento_s:before {
        content: "\e90b";
    }
    &.icon-atendimento_g:before {
        content: "\e90c";
    }
    &.icon-calc-2:before {
        content: "\e901";
    }
    &.icon-calc-desk:before {
        content: "\e902";
    }
    &.icon-calc-mobile:before {
        content: "\e903";
    }
    &.icon-chat-2:before {
        content: "\e904";
    }
    &.icon-chat-desk:before {
        content: "\e905";
    }
    &.icon-chat-mobile:before {
        content: "\e906";
    }
    &.icon-depoimentos:before {
        content: "\e907";
    }
    &.icon-whatsapp-blod:before {
        content: "\e908";
    }
    &.icon-whatsapp-desk:before {
        content: "\e909";
    }
    &.icon-whatsapp-mobile:before {
        content: "\e90a";
    }
}

.seguradoraSprite {
    background-image: url(../images/spritesheet.png);
    background-repeat: no-repeat;
    display: block;

    &.seguradoraSprite-seguradora-aig {
        width: 141px;
        height: 87px;
        background-position: -5px -5px;
    }

    &.seguradoraSprite-seguradora-alfa {
        width: 141px;
        height: 87px;
        background-position: -156px -5px;
    }

    &.seguradoraSprite-seguradora-alianz {
        width: 141px;
        height: 87px;
        background-position: -307px -5px;
    }

    &.seguradoraSprite-seguradora-azul {
        width: 141px;
        height: 87px;
        background-position: -5px -102px;
    }

    &.seguradoraSprite-seguradora-bradesco {
        width: 141px;
        height: 87px;
        background-position: -156px -102px;
    }

    &.seguradoraSprite-seguradora-chubb {
        width: 141px;
        height: 87px;
        background-position: -307px -102px;
    }

    &.seguradoraSprite-seguradora-excelsior-seguros {
        width: 141px;
        height: 87px;
        background-position: -5px -199px;
    }

    &.seguradoraSprite-seguradora-hdi {
        width: 141px;
        height: 87px;
        background-position: -156px -199px;
    }

    &.seguradoraSprite-seguradora-itau {
        width: 141px;
        height: 87px;
        background-position: -307px -199px;
    }

    &.seguradoraSprite-seguradora-liberty {
        width: 141px;
        height: 87px;
        background-position: -5px -296px;
    }

    &.seguradoraSprite-seguradora-mapfre {
        width: 141px;
        height: 87px;
        background-position: -156px -296px;
    }

    &.seguradoraSprite-seguradora-maritima {
        width: 141px;
        height: 87px;
        background-position: -307px -296px;
    }

    &.seguradoraSprite-seguradora-mitsui {
        width: 141px;
        height: 87px;
        background-position: -5px -393px;
    }

    &.seguradoraSprite-seguradora-porto {
        width: 141px;
        height: 87px;
        background-position: -156px -393px;
    }

    &.seguradoraSprite-seguradora-suhai {
        width: 141px;
        height: 87px;
        background-position: -307px -393px;
    }

    &.seguradoraSprite-seguradora-sulamerica {
        width: 141px;
        height: 87px;
        background-position: -458px -5px;
    }

    &.seguradoraSprite-seguradora-tokio {
        width: 141px;
        height: 87px;
        background-position: -458px -102px;
    }

    &.seguradoraSprite-seguradora-zurich {
        width: 141px;
        height: 87px;
        background-position: -458px -199px;
    }

}
