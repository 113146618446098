// SETA O MAX DO CONTAINER
.container {
  max-width: 100%;
}

// FOOTER FIXO COM DISPLAY FLEX
body {
  @include displayflex;
  @include directioncolumn;
  @include justifycenter;
  @include alignstretch;
  @include flexnowrap;

  overflow-x: hidden;
  min-height: 100vh;

  &.loading{
    overflow: hidden;
  }
}

main {
  @include flexgrow(1);
}

// limitar imagens aos seus container
img {
  max-width: 100%;
}

// COR DOS LINKS
a {
  color: $main-blue;
  &:hover {
    color: $light-blue;
  }
}

*:focus{
  outline: 0 !important;
}

// SECTIONS
//////////////////////////////////////////////////////////
.section-full {
  width: 100%;
  margin: 50px 0;
  padding: 0;
  float: left;


  &.section-blue {
    background: #4dcde4;
    margin: 0;
    padding: 50px 0;
    color: #FFF;

    p {
      color: #FFF;
    }

    h1 {
      margin-top: 0;
    }

    iframe {
      width: 100%;
    }
  }
}

.left {
  float: left;
}

.right {
  float: right;
}

.no-margin {
  margin: 0;
}

section,
.section {
  background-size: cover;
  background-repeat: no-repeat;
  //overflow: hidden;
  position: relative;
  background-position: center center;
}

.darker-blue-sec {
  background: $darker-blue;

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.dark-blue-sec {
  background: $dark-blue;

  a {
    color: #fff;
    &:hover {
      color: $light-blue;
    }
  }
}

.main-blue-sec {
  background: $main-blue;

  a {
    color: #fff;
    &:hover {
      color: $light-blue;
    }
  }
}

.light-blue-sec {
  background: $light-blue;

  a {
    color: $dark-blue;
    &:hover {
      color: $darker-blue;
    }
  }
}

// HEADER
.main-header {
  z-index: 1000;

  .top-bar {
    padding: 15px 0;
    border-top: 5px solid $footer-bg;

    .icon-top-links {
      float: right;
    }

    .item-icon {
      display: inline-block;
      margin: 0 20px;
      line-height: 1em;
      vertical-align: middle;

      &:last-child {
        margin-right: 0;
      }

      &:before {
        float: left;
        font-size: 1.6em;
        margin: -2px 10px 0 0;
      }
    }

    div, a, span {
      color: #fff;
    }

    a {
      font-size: #{14/$b-font-size}em;
      font-weight: 600;
      text-transform: uppercase;
    }

    .icon-tel {
      a {
        font-size: #{16/$b-font-size}em;
        font-weight: 700;
      }
    }
  }

}

@media screen and (min-width: 992px) {
  .main-bar {
    height: 60px;
    border-top: 5px solid $pastel-blue;

    nav {
      float: right;
      height: 60px;
      margin-top: -5px;

      li {
        display: inline-block;
      }

      .seta-menu {
        display: none;
      }

      a {
        font-size: 1em;
        font-weight: 700;
        display: inline-block;
        padding: 14px 33px;
        height: 60px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        text-transform: uppercase;

        &:hover {
          color: $light-blue;
          text-decoration: none;
          border-top: 5px solid $light-blue;
          border-bottom: 5px solid $light-blue;
        }

        &:before {
          display: none;
        }
      }

      .menu-item.active > a {
        color: $light-blue;
        text-decoration: none;
        border-top: 5px solid $light-blue;
        border-bottom: 5px solid $light-blue;
      }
    }
  }
  .with-sub {
    position: relative;
    ul {
      display: none;
      position: absolute;
      left: 0;
      top: 60px;

      background: #fff;
      border: 1px solid $light-blue;

      a {
        color: $light-blue;
        width: 100%;
        font-size: 0.8em;
        padding: 8px 15px;
        height: auto;
        min-width: 120px;

        &:hover {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          background: $light-blue;
          color: #fff;
        }
      }

      li {
        width: 100%;
        min-width: 180px;
      }
    }

    .sub-sub {
      position: relative;
      ul {
        position: absolute;
        left: 100%;
        top: 0;
        right: initial;
        columns: 2;

        a {
          &:after {
            display: none;
          }
        }
      }

      a {
        &:after {
          content: '';
          display: block;
          float: right;
          margin-top: 5px;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;

          border-left: 5px solid $light-blue;
        }
      }

      &:hover {
        a {
          &:after {
            border-left: 5px solid #fff;
          }
        }

      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main-header {
    .main-bar {
      nav {
        a {
          padding: 14px 20px;
        }
      }
    }
  }
}

// SIDEBAR FIXA
body {
  padding-left: 12%;
  @include transition(padding-left, .3s, ease-in);
}

.marca {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;

  display: block;
  width: 12%;
  height: 128px;
  border-bottom: 1px solid $light-gray;

  a {
    background: $light-color;
    display: block;
    width: 100%;
    height: 100%;
    padding: 15px;
    border-right: 1px solid $light-gray;

    img {
      vertical-align: middle;
    }

    &:before {
      content: '';
      height: 100%;
      vertical-align: middle;
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  padding-top: 128px;

  background: $light-color;
  display: block;
  width: 12%;
  height: 100%;
  border-right: 1px solid $light-gray;

  nav {
    height: 100%;
  }

  ul {
    height: 100%;
    display: inline;
  }

  li {
    height: 25%;
    display: block;
    border-bottom: 1px solid #b4b4b4;
    text-align: center;
    @include transition(border-left, .3s, ease);

    @media all and (min-width: 996px){
      &:hover{
        background: $light-color-hover;
        text-decoration: none;
        border-top: 1px solid $light-gray;
        border-left: 15px solid $light-blue;
      }
    }

    a {
      text-decoration: none;
      display: inline-block;
      font-weight: 700;
      color: #787878;
      text-transform: uppercase;
      transition: border-left 0.3s ease;
      top: 50%;
      position: relative;
      transform: translateY(-50%);

      &:focus {
        text-decoration: none;
      }

      @media all and (max-width: 1300px){
        font-size: 15px;
      }


      span {
        vertical-align: middle;
        display: block;
        //white-space: nowrap;
      }

      i {
        display: block;
        color: $light-blue;
        font-size: 3em;
        margin-bottom: 10px;
      }

      big {
        color: $light-blue;
        display: block;
        font-weight: 800;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  body {
    padding-left: 0;
  }

  .main {
    padding-top: 60px;
  }

  .menu-mobile-toggle {
    width: 26px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 22px;
    z-index: 9999;

    i,
    &:before,
    &:after {
      background: $light-blue;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      display: block;
    }

    i,
    &:after {
      margin-top: 4px;
    }

    &:before,
    &:after {
      content: '';
    }
  }

  .main-header {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;

    .main-menu-list {
      display: none;
      background: $main-blue;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
    }

    .main-bar {
      background: $menu-mobile;
      border: 0;

      nav {
        li {
          width: 100%;
          display: inline-block;

          .first-level {
            width: 100%;
            font-size: #{14/$b-font-size}em;
            font-weight: 600;
            height: 59px;
            padding: 17px;
            line-height: 1.9em;
            border: 0;
            display: block;
            float: left;
            color: #fff;
            text-transform: uppercase;

            &:before {
              display: inline-block;
              color: $light-blue;
              margin-right: 15px;
              float: left;
              font-size: 2em;
            }
          }

          // PRIMEIRO NÍVEL
          &.with-sub {

            .first-level:after {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              float: right;
              margin: 9px;

              @include rotate(-45deg);
            }

            & > a:hover {
              border: 0;
              background: $dark-blue;
              color: #fff;
              text-decoration: none;
            }

            .sub-sec {
              overflow: scroll;
              display: block;
              width: 100%;
              height: 100%;
              background: $dark-blue;
              border: 0;

              @include transition(right, .4s, ease-in);

              position: fixed;
              top: 60px;
              left: initial;
              right: -600px;
              z-index: 99999;

              padding-bottom: 120px;

              li {
                a {
                  padding-left: 35px;
                  font-size: 0.9em;
                  text-transform: none;
                  width: 80%;
                  display: inline-block;
                }
              }

            }

            // SETA DO PRIMEIRO NÍVEL
            .seta-menu {
              padding: 10px;
              float: right;
              width: 20%;
              height: 28px;
              display: block;
              padding: 25px 20px;
              line-height: 1.9em;

              &:before {
                content: '';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-bottom: 2px solid #fff;
                border-right: 2px solid #fff;
                float: right;
                margin: 0;

                @include rotate(-45deg);
              }
            }
          }

          &.active {
            background: $dark-blue;

            .sub-sec {
              right: -60px;
              padding-right: 60px;
            }
          }

          // LINKS QUE TEM SEGUNDO NÍVEL
          .sub-sub {
            .seta-menu {
              padding: 20px 20px 27px;

              &:before {
                @include transition(transform, .3s, ease);
              }
            }

            ul {
              position: relative;
              left: initial;
              right: initial;
              top: initial;
              float: left;
              background: $darker-blue;

              max-height: 0;
              height: 100%;
              overflow: hidden;
              @include transition(max-height, 2s, ease);

              a {
                font-size: 0.8em;
                font-weight: 300;
                padding: 10px 17px;
                height: 43px;
              }
            }

            // ACTIVE
            &.activesub {
              .seta-menu {
                &:before {
                  @include rotate(45deg);
                }
              }

              ul {
                max-height: 2000px;
              }
            }
          }

          // VOLTAR PRIMEIRO NIVEL
          .back-first-level {
            font-size: 1.1em;
            padding: 17px 35px;
            display: inline-block;
            width: 100%;
            color: #fff;
            font-weight: 600;
            position: relative;

            &:before {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              float: right;
              margin: 0;

              position: absolute;
              left: 13px;
              top: 27px;

              @include rotate(135deg);
            }
          }
        }

        .menu-item.active > a {
          border: 0;
        }
      }
    }

  }

  .marca {
    position: relative;
    height: auto;
    width: 100px;
    border: 0;
    margin: 12px 0;

    a {
      padding: 0;
      background: 0;
      border: 0;

      &:before {
        display: none;
      }
    }
  }

  .sidebar {
    padding: 0;
    width: 100%;
    height: 60px;
    bottom: 0;
    top: initial;
    border: 0;
    z-index: 99999;

    li {
      height: 100%;
      width: 25%;
      display: inline-block;
      float: left;
      border: 0;

      a {
        &:hover {
          border: 0;
        }

        span {
          display: none;
        }

        i {
          margin: 0;
          font-size: 2em;
        }
      }

      &.calculadora {
        a i:before {
          content: '\e903';
        }
      }
      &.chat {
        a i:before {
          content: '\e906';
        }
      }
      &.whatsapp {
        a i:before {
          content: '\e90a';
        }
      }
      &.atendimento {
        a i:before {
          content: '\e90b';
        }
      }
    }
  }
}

// BANNER GERAL E DA HOME
.banner-sec {
  margin: 0;

  .block-title {
    span {
      font: inherit;
      //white-space: nowrap;
    }
  }

  .block-text {
    ul {
      padding: 0;
      margin-bottom: 20px;
      font-size: 1.125em;

      li {
        color: #fff;
        font: inherit;
        list-style: none;
        margin-bottom: 10px;

        &:before {
          content: '» ';
        }
      }
    }
  }

  &.banner-home {
    padding: 200px 0 235px;
    background-position: bottom right;
    big {
      float: left;
      font-size: 3.3em;
      line-height: 0.4em;
      color: $light-blue;
      font-style: italic;
      margin-right: 15px;
    }

    .h2 {
      font-weight: 700;
      line-height: 0.9em;
      min-width: 420px;
    }

    .bighome {
      font-size: 1.350em;
    }
  }

  &.banner-mobile {
    background-size: cover;
    background-position: center center;
    padding: 0;
  }

  &.banner-default {
    position: relative;
    padding: 80px 0;

    .quotationForm {
      white-space: nowrap;

      @media all and (max-width: 768px){
        white-space: normal;

        .select2-container {
          max-width: 100%;
        }
      }

      label.error {
        color: red;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 60%;
      height: 100%;
      left: 30%;
      top: 0;
      z-index: 0;

      background: linear-gradient(right, #202020, rgba(0, 0, 0, 0)); /* Standard syntax */
      background: -o-linear-gradient(right, #202020, rgba(0, 0, 0, 0)); /* For Opera 11.1 to 12.0 */
      background: -moz-linear-gradient(right, #202020, rgba(0, 0, 0, 0)); /* For Firefox 3.6 to 15 */
      background: -webkit-linear-gradient(left, #202020, rgba(0, 0, 0, 0)); /* For Safari 5.1 to 6.0 */

    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 30%;
      height: 100%;
      left: 0;
      top: 0;
      background: #202020;
      z-index: 0;
    }

    .container {
      position: relative;
      z-index: 10;
    }

    .h1 {
      strong {
        font-size: 1.8em;
        line-height: 0.8em;
        color: $light-blue;
        display: block;
      }
    }

    .block-select {
      margin: 40px 0 30px;
    }

    .link-banner {
      a {
        font-size: #{21/$b-font-size}em;
        margin: 0 10px 0 0;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .block-text {
    margin: 40px 0;
    line-height: 1.4em;
  }

  .dropdown-toggle {
    min-width: 220px;
  }

  p {
    color: #fff;
  }
}

@media screen and (max-width: 1200px) {
  .banner-sec.banner-home {
    background-position: -300px 0px;

    h2 {
      font-size: 1.6em;
    }
  }
}

@media screen and (max-width: 1100px) {
  .banner-sec.banner-home {
    background-position: -380px 0px;
  }
}

@media screen and (max-width: 991px) {
  .banner-sec {
    width: 100%;
    height: 100%;

    .container {
      padding: 0;
    }

    &.banner-home {
      background-position: bottom right;
      .block-banner {
        padding-bottom: 60px;
        background: $menu-mobile;
        text-align: center;

        h2 {
          min-width: initial;
          width: 310px;
          margin: 50px auto 0;
        }
      }
    }

    &.banner-default {
      text-align: center;

      &:before, &:after {
        display: none;
      }

      .link-banner {
        text-align: center;
        a {
          font-size: #{18/$b-font-size}em;
          margin: 5px 0;
          font-weight: 600;
        }
      }
    }
  }
}

// SEÇÃO DE SIMULE E CONTRATE DA HOME
.simule-home {
  margin-bottom: 0;

  h2 {
    color: $main-blue;
    strong {
      color: $light-blue;
    }
  }

  .sec-title span {
    float: right;
    font-size: 9.4em;
    font-weight: 800;
    line-height: 1.1em;
    color: $light-blue;
  }

  p {
    margin: 20px 0;
  }

  .sec-img {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .simule-home {
    text-align: center;

    h2 {
      color: #fff;
      strong {
        color: $main-blue;
      }

    }
    .sec-title span {
      display: none;
    }

    .sec-content {
      background: $light-blue;
      padding-bottom: 50px;
      padding-top: 20px;
    }

    .sec-link a {
      background: $main-blue;
      color: #fff;
      &:hover {
        color: #fff;
        background: $pastel-blue;
      }
    }
  }
}

// SEÇÃO DE CURIOSIDADE DA HOME
.sec-curiosidade {
  margin-top: 0;
  padding: 40px 0;

  .title-sec {
    text-align: center
  }

  big {
    font-size: 2em;
    line-height: 0.8em;
  }
}

// SEÇÕES DOS TIPOS DE SEGURO
.sec-seguros {

  .container {
    position: relative;
  }

  .sec-content {
    margin: 60px 0;
  }

  .seguro-item {
    width: 265px;
    height: 265px;
    display: inline-block;

    .seguro-img {
      height: 55%;

      i {
        color: #fff;
        font-size: 11em;
        line-height: 0.9em;
        display: block;

        &.ico-m {
          font-size: 9em;
          line-height: 1em;
        }

        &:before { line-height: 0;}
      }
    }

    h4 {
      color: #fff;
      margin-bottom: 10px;
    }

    p {
      color: $main-blue;
      line-height: 1.1em;
      font-size: #{14/$b-font-size}em;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      background: $light-blue;
      padding: 20px;
      text-align: center;

      @include transition(background, .3s, ease);

      &:hover {
        text-decoration: none;
        background: $main-blue;

        p {
          color: #fff;
        }
      }
    }
  }

  .sec-content-mobile {
    .seguro-item {
      max-width: 100%;
      h4 {
        color: $light-blue;
      }

      p {
        color: $dark-blue;
        font-weight: 700;
        line-height: 1.1em;
        font-size: 1em;
      }
    }
  }

  &.sec-diferenciados {
    margin-bottom: 0;

    .container {
      padding-bottom: 60px;

      &:after {
        width: 192px;
        height: 318px;
        display: block;
        position: absolute;
        right: -70px;
        bottom: -10px;

        background: url(../images/sprite.png) no-repeat 0 0;
      }
    }
  }

  &.sec-seguros-empresa {
    padding: 30px 0 50px;

    .container {
      padding-bottom: 60px;

      &:before {
        width: 192px;
        height: 318px;
        display: block;
        position: absolute;
        left: -90px;
        bottom: -50px;

        background: url(../images/sprite.png) no-repeat -250px 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-seguros {
    text-align: center;

    .sec-content {
      margin: 30px 0 10px;
    }

    .seguro-item {
      .seguro-img {
        i {
          color: $light-blue;
        }
      }
    }

    &.sec-seguros-empresa {
      .sec-content-mobile .seguro-item p {
        color: #fff;
      }
    }

    &.sec-diferenciados {
      .container {
        &:after {
          display: none;
        }
      }
    }

    &.sec-seguros-empresa {
      .container {
        &:before {
          display: none;
        }
      }
    }
  }
}

// SECTION DOS DEPOIMENTOS
.sec-depoimentos {
  padding: 40px 0;

  .sec-title {
    text-align: center;
  }

  i {
    font-size: 12em;
    margin-bottom: 5px;
    line-height: 0.7;
    height: 126px;
    display: block;

    &:before {
      line-height: 0;
    }
  }
  .h2 {
    margin: 0;
  }

  .sec-content {
    width: 90%;
    margin: 0 auto;

    .h5 {
      color: #fff;
      margin: 10px 0;
    }

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-depoimentos {

    .sec-title {
      margin-bottom: 30px;
    }

    .sec-content {
      width: 100%;
    }
  }
}

// SEÇÃO DAS SEGURADORAS - LISTA COM SWIPE
.sec-seguradoras {
  .h3 {
    big {
      color: $light-blue;
      font-size: 2em;
    }

    strong {
      color: $light-blue;
    }
  }

  .seguradora-item {
    width: 150px;
    text-align: center;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    @include transition(filter, .3s, ease);
    @include transition(-webkit-filter, .3s, ease);

    &:hover {
      -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
      filter: grayscale(0%);
    }
  }

  .sec-content {
    margin: 30px 0;
  }
}

// SECTION COM BG E TITLE, EX.: SEC DE EQUIPE NA HOME
.sec-bg-full {
  padding: 40% 0 5%;

  .sec-block {
    width: 590px;
    max-width: 100%;
    position: relative;

    .h1 {
      color: $light-blue;
      font-size: 4.5em;

      strong {
        color: #fff;
        display: block;
      }
    }

    .block-content {
      position: relative;
      z-index: 9;
    }

    &:after {
      content: '';
      display: block;
      width: 200%;
      height: 200%;
      background: url(../images/bg-shadow.png) no-repeat;
      background-size: contain;
      position: absolute;
      left: -25%;
      top: -40%;
      z-index: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-bg-full {
    padding: 40px 0 50px;

    .sec-block {
      width: 100%;
      text-align: center;

      .h1 {
        font-size: 1.625em;
        color: #fff;
      }

      p {
        margin-bottom: 40px;
      }

      &:after {
        display: none;
      }
    }
  }
}

// FOOTER
.footer {
  background: $footer-bg;
  padding: 70px 0 100px;

  .nav-footer {
    border-bottom: 6px solid $light-blue;
    padding: 0 0 40px;

    @include displayflex;
    @include flexnowrap;

    ul > a {
      font-weight: 600;
    }

    ul {
      margin-bottom: 20px;
    }

    li {
      a {
        color: #fff;
        font-weight: 200;
      }
    }
  }

  .footer-col {
    float: left;
    padding: 0 40px;
    width: 25%;
    max-width: 280px;
    min-height: 100%;

    border-width: 6px 6px 6px 0;
    border-style: solid;
    -webkit-border-image: -webkit-linear-gradient(rgba(0, 0, 0, 0), $pastel-blue, rgba(0, 0, 0, 0)) 1 100%;
    -moz-border-image: -moz-linear-gradient(rgba(0, 0, 0, 0), $pastel-blue, rgba(0, 0, 0, 0)) 1 100%;
    -o-border-image: -o-linear-gradient(rgba(0, 0, 0, 0), $pastel-blue, rgba(0, 0, 0, 0)) 1 100%;
    border-image: linear-gradient(rgba(0, 0, 0, 0), $pastel-blue, rgba(0, 0, 0, 0)) 1 100%;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      border: 0;
    }
  }

  .redes-sociais {
    li {
      float: left;
      font-size: 1.5em;
      margin: 5px;
    }

    a:hover {
      text-decoration: none;
      color: $light-blue;
    }
  }

  .redes-sociais-mobile {
    display: none;
  }

  .footer-info {
    margin-top: 30px;

    p {
      font-weight: 200;
      font-size: #{13/$b-font-size}em;
      color: #fff;
    }

    a {
      color: $light-blue;
    }
  }

  .footer-logos {
    margin: 30px 0 0;
    font-size: #{11/$b-font-size}em;
    color: #fff;

    a {
      margin: 0 5px;
      display: inline-block;
    }
  }
}

@media screen and (max-width: 991px) {
  .footer {
    .nav-footer {
      display: none;
    }

    .redes-sociais-mobile {
      display: block;
      text-align: center;

      li {
        font-size: 2em;
        margin: 0 5px;
        display: inline-block;

        a {
          color: #fff;
        }
      }
    }

    .footer-info {
      text-align: center;
    }

    .footer-logos {
      text-align: center;

      .others {
        display: none;
      }

      .exceler {
        float: none;
        img {
          width: 80px;
        }
      }
    }
  }
}

// SECTION DE TEMPLATE DOS SEGUROS - SEC BRANCA E AZUL
.seguro-default-sec {
  padding: 100px 0 0;

  .main-text-block {
    margin-bottom: 80px;
  }

  .block-image {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.seguro-default-sec-blue {
  padding: 50px 0;
  overflow: visible;

  .sec-block-img {
    margin-top: -100px;
  }
}

@media screen and (max-width: 991px) {
  .seguro-default-sec {
    text-align: center;

    .block-image {
      position: relative;
    }
  }
  .seguro-default-sec-blue {
    text-align: center;
  }
}

// SECTION LISTAGEM DE PRODUTOS
.sec-listagem-produtos {
  padding: 30px 0;

  .container {
    padding: 0;
  }

  .produto-item {
    width: 220px;
    max-width: 100%;
    padding: 15px;
    display: inline-block;
    text-align: center;

    .h5 {
      font-size: #{14/$b-font-size}em;
      margin: 0;
      font-weight: 600;
    }

    p {
      font-size: #{12/$b-font-size}em;
      font-weight: 600;
    }
  }

  .produtos-link {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .sec-listagem-produtos {
    padding-bottom: 0;
    margin-bottom: 0;

    .container {
      padding: 0 50px;
    }
  }
}

// TEMPLATE CENTRAL DE ATENDIMENTO
.sec-listagem-seguradoras {
  padding: 30px 0;

  .container {
    padding: 0;
  }

  .seguradora-item {
    width: 220px;
    max-width: 100%;
    padding: 15px;
    display: inline-block;
    text-align: center;

    .h5 {
      font-size: #{18/$b-font-size}em;
      margin: 0;
    }

    p {
      font-size: #{14/$b-font-size}em;
      font-weight: 600;
      margin: 0;
    }

    a {
      display: block;
      width: 100%;
      margin: 0 0 5px;
      font-size: #{18/$b-font-size}em;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-listagem-seguradoras {
    padding-bottom: 0;
    margin-bottom: 0;

    .container {
      padding: 0 50px;
    }
  }
}

// SECTION QUEM SOMOS - MISSAO VISAO E VALORES
.sec-dna {
  padding: 30px 0;

  .title-sec {
    height: 350px;
    background: url(../images/bg-quem-somos.png) no-repeat left top;
    background-size: contain;

    .h5 {
      display: inline-block;
      width: 55%;
      padding: 20px;
      border-radius: 20px;
      background: #fff;
      float: right;
      position: relative;
      font-size: 1.35em;
      line-height: 1.3em;

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 40px solid #fff;
        position: absolute;
        top: 20%;
        left: -37px;
      }
    }
  }

  .dna-item {
    width: 33%;
    float: left;
    min-width: 200px;
    padding: 10px;

    p {
      color: #fff;
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-dna {
    .dna-item {
      width: 100%;
    }
  }
}

// SECTION DIFERENCIAIS MAZETTO
.sec-diferenciais {
  .sec-title {
    font-size: #{100/$b-font-size}em;
    strong {
      display: block;
      color: $light-blue;
    }
  }

  .sec-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .diferenciais-item {
    width: 100%;
    margin: 20px 0;
    display: inline-block;

    .diferenciais-txt {
      width: calc(100% - 200px);
      float: right;
    }

    .h1 {
      margin-bottom: 10px;
      margin-top: 0;
    }

    p {
      font-weight: 600;
    }

    .diferenciais-img {
      float: left;
      margin: 0 20px 0 0;
    }
  }
}

@media screen and (max-width: 1340px) {
  .sec-diferenciais {
    .sec-title {
      font-size: #{80/$b-font-size}em;
    }
  }
}

@media screen and (max-width: 991px) {
  .sec-diferenciais {
    text-align: center;

    .sec-title {
      font-size: #{40/$b-font-size}em;
    }

    .diferenciais-item {
      .diferenciais-txt {
        width: 100%;
        float: left;
      }
      .diferenciais-img {
        float: left;
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}

// FORM DE CONTATOS EXPRESSO
.form-expresso {
  position: fixed;
  top: 300px;
  right: -265px;
  z-index: 999;

  @include transition(right, .5s, ease-in);

  &.active {
    right: 0;
  }

  .toggle-form-expresso {
    display: block;
    float: left;
    width: 120px;
    height: 114px;
    cursor: pointer;
    background: url(../images/bg-form-expresso.png) no-repeat top right;
  }

  .form-content {
    width: 260px;
    float: right;
    padding: 20px 15px;
    border-radius: 0 0 0 20px;
    background: $light-blue;

    .form-item {
      input {
        padding: 5px;
        font-size: 1em;
        margin: 0 5px;
        border-radius: 10px;
      }
    }

    .form-submit {
      text-align: right;
    }
  }
}

@media screen and (max-width: 991px) {
  .form-expresso {
    display: none;
  }
}

// PÁGINA DE COTAÇÃO
// bodyid
#cotacao {
  .cotacao-sec {
    &:before {
      width: 60%;
      left: 50%;
    }

    &:after {
      width: 50%;
    }
  }

  .step-content {
    display: none;

    &:first-child {
      display: block;
    }
  }

  .form-prev-step,
  .form-next-step {
    display: inline-block;
  }

  .form-prev-step {
    margin-right: 10px;
  }

  form {
    .form-item {

      &.small-input {
        input {
          width: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  #cotacao {
    .block-banner {
      padding: 0 40px;
    }
  }
}

// PÁGINA 404
#page404 {
  .main {
    background: #191c1c;
  }

  .sec-404 {
    padding-bottom: 300px;
    background: url(../images/404.png) no-repeat center bottom;

    &:before,
    &:after {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  #page404 {
    .sec-404 {
      background-size: contain;
    }
  }
}

// MODAL
.modal {

  &.trabalhe-conosco {
    display: none;

    position: fixed;
    overflow: scroll;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
    z-index: 99999;

    .h2 {
      margin-top: 0;
    }

    .modal-content {
      background: url(../images/modal-trabalhe-bg.jpg) no-repeat center center;
      background-size: cover;
      max-width: 100%;
      width: 800px;
      margin: 50px auto;
      padding: 30px;
    }

    .form-line {
      input,
      textarea{
        text-align: left;
      }

      label {
        -webkit-transition: background .3s ease;
        -moz-transition: background .3s ease;
        -ms-transition: background .3s ease;
        -o-transition: background .3s ease;
        transition: background .3s ease;
        color: #fff;
        font-weight: 400;
        font-size: 1.2em;
      }

      &.upload-file {
        input {
          position: absolute;
          height: 43px;
          width: 270px;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    .modal-close {
      float: right;
      width: 30px;
      height: 30px;
      color: #fff;
      cursor: pointer;

      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 2em;
      line-height: 0;
    }
  }
}

.select2-container {
  height: 46px;
  border: 1px solid #aaa;
  border-radius: 26px;

  .select2-selection--single {
    border: 0;
    background: transparent;

    .select2-selection__rendered {
      color: #FFF;
      line-height: 46px;
      padding-left: 15px;
    }

    .select2-selection__arrow {
      margin-right: 7px;
      height: 42px;
    }
  }
}