// TÍTULOS E PARÁGRAFOS
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {

  font-family: 'Exo 2', sans-serif;
  text-transform: uppercase;

  span, strong, small {
    font: inherit;
    color: inherit;
    font-weight: normal;
  }
  strong {
    font-weight: 800;
  }
  small {
    font-size: 0.5em;
    display: block;
  }
}

// CORES DE TEXTO
.txt-preto {
  color: #353a3d;
}

.txt-branco {
  color: #fff;
}

.txt-center {
  text-align: center;
}

.txt-blue {
  color: $main-blue
}

.txt-light-blue {
  color: $light-blue
}

.txt-bold {
  font-weight: 600;
}

h1, .h1 {
  font-size: #{49/$b-font-size}em;
  margin: 20px 0 30px;
  font-weight: 900;
}

h2, .h2 {
  font-size: #{41/$b-font-size}em;
  margin: 20px 0;
  font-weight: 900;
}

h3, .h3 {
  font-size: #{26/$b-font-size}em;
  margin: 20px 0;
  font-weight: 900;
}

h4, .h4 {
  font-size: #{22/$b-font-size}em;
  margin: 20px 0;
  font-weight: 900;
}

h5, .h5 {
  font-size: #{20/$b-font-size}em;
  margin: 20px 0;
  font-weight: 900;
}

h6, .h6 {
  font-size: #{18/$b-font-size}em;
  margin: 20px 0;
  font-weight: 900;
}

@media screen and (max-width: 1300px) {
  h1, .h1 {
    font-size: #{49/$b-font-size}em;
  }
  h2, .h2 {
    font-size: 2em;
  }
  h3, .h3 {
    font-size: #{21/$b-font-size}em;
  }
  h4, .h4 {
    font-size: #{20/$b-font-size}em;
  }
  h5, .h5 {
    font-size: #{18/$b-font-size}em;
  }
  h6, .h6 {
    font-size: #{18/$b-font-size}em;
  }
}

@media screen and (max-width: 991px) {
  h1, .h1 {
    font-size: 1.625em;
    margin: 20px 0 30px;
  }
  h2, .h2 {
    font-size: 1.5em;
    margin: 20px 0;
  }
  h3, .h3 {
    font-size: #{21/$b-font-size}em;
    margin: 20px 0;
  }
  h4, .h4 {
    font-size: #{20/$b-font-size}em;
    margin: 10px 0;
  }
  h5, .h5 {
    font-size: #{18/$b-font-size}em;
    margin: 10px 0;
  }
  h6, .h6 {
    font-size: #{18/$b-font-size}em;
    margin: 10px 0;
  }
}

// TITULOS
.default-title {
  big {
    font-size: 1.950em;
    display: block;
    line-height: 0.8em;
  }
}

@media screen and (max-width: 991px) {
  .default-title {
    big {
      font-size: 1.5em;
      display: block;
    }
  }
}

.light-blue-title {
  big {
    color: light-blue
  }
}

p {
  font-family: $font-family-base;
  font-size: #{18/$b-font-size}em;
  color: $main-blue;

  strong {
    font-weight: 600;
  }
  del {
    text-decoration: line-through;
  }
}

@media screen and (max-width: 991px) {
  p {
    font-size: 1em;
  }
}

small {
  font-size: 80%;
}

// BREADCRUMB
.breadcrumb {
  li {
    display: inline-block;
    margin: 0;

    span {
      color: $medium-gray;
    }

    a {
      color: $light-blue;
      span {
        color: $main-blue;
      }
    }
  }
}

// SUBTITLE
.subtitle {
  font-size: #{22/$b-font-size}em;
  line-height: 1.1em;
}

@media screen and (max-width: 991px) {
  .subtitle {
    font-size: 1.2em;
  }
}

// COR DOS LINKS
a {
  @include transition(color, .3s, ease);
  &:hover {
  }
}

// RETIRA FORMATAÇÃO DAS ULs E LIs
.non-style-list {
  list-style: none;
  padding: 0;
  margin: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
}

// GRUPO DE BOTÕES

button, a, label {
  &[class^="btn-"],
  &[class*="btn-"] { cursor: pointer; }
}

textarea{
  &[class^="btn-"],
  &[class*="btn-"]{
    border-radius: 18px;
  }
}

textarea, input{
  &[class^="btn-"], &[class*="btn-"]{
    text-transform: none;
  }
}

// General
[class^="btn-"], [class*="btn-"] {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-family: 'Exo 2', sans-serif;
}

.btn-ss {
  padding: 12px 15px;
  font-size: #{18/$b-font-size}em;
  line-height: 1.1em;
  font-weight: 400;
  border-radius: 60px;
  min-width: 80px;
}

.btn-s {
  padding: 15px 20px;
  font-size: #{21/$b-font-size}em;
  line-height: 1.1em;
  font-weight: 900;
  border-radius: 60px;
}

.btn-m {
  padding: 20px 50px;
  font-size: #{21/$b-font-size}em;
  line-height: 1.1em;
  font-weight: 700;
  border-radius: 60px;
}

.btn-l {
  padding: 20px 60px;
  font-size: #{30/$b-font-size}em;
  line-height: normal;
  font-weight: 400;
  border-radius: 60px;
}

@media screen and (max-width: 991px) {
  .btn-s {
    font-size: 1.2em;
    padding: 13px;
  }
  .btn-m {
    font-size: 1.1em;
    padding: 15px 20px;
  }

  .btn-l {
    padding: 15px 20px;
    font-size: 1.3em;
  }
}

[class^="btn-flat-"], [class*="btn-flat-"] {
  @include transition(background, .3s, ease);
  @include transition(color, .3s, ease);
  border: 0;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.btn-flat-light-blue {
  background: $light-blue;
  color: #fff;
  &:hover {
    color: #fff;
    background: $menu-mobile;
  }
}

.btn-flat-main-blue {
  background: $main-blue;
  color: #fff;
  &:hover {
    color: #fff;
    background: $menu-mobile;
  }
}

.btn-flat-light-green {
  background: $light-green;
  color: #fff;
  &:hover {
    color: #fff;
    background: $main-green;
  }
}

// GHOST BUTTONS
[class^="btn-ghost-"], [class*="btn-ghost-"] {
  @include transition(border, .3s, ease);
  @include transition(color, .3s, ease);
}

.btn-ghost-white {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  &:hover {
    color: $light-blue;
    border: 1px solid $light-blue;
  }
}

.btn-arrow-right {
  &:after {
    content: '';
    display: inline-block;
    margin-left: 20px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-left: 10px solid #fff;
  }
}

.btn-arrow-left {
  &:after {
    content: '';
    display: inline-block;
    margin-left: 20px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;

    border-right: 10px solid #fff;
  }
}

.group-btn {
  width: 100%;

  [class^="btn-"], [class*="btn-"] {
    margin-right: 20px;
    &:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .group-btn {
    [class^="btn-"], [class*="btn-"] {
      margin-right: 0;
      padding: 11px 10px;
      min-width: 90px;
      font-size: 1.1em;
    }
  }
}

// SWIPER STYLE
.sec-swiper {
  .container {
    padding: 0 50px;
  }

  .sec-content {
    position: relative;
  }
}

.swiper-prev,
.swiper-next {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-bottom: 2px solid $darker-blue;
  border-right: 2px solid $darker-blue;

  position: absolute;
  top: 50%;
  margin-top: -10px;

  &.swiper-button-disabled {
    opacity: (0.5);
  }
}

.swiper-next {
  right: -30px;
  @include rotate(-45deg);
}

.swiper-prev {
  left: -30px;
  @include rotate(-230deg);
}

.dark-blue-sec,
.darker-blue-sec,
.main-blue-sec {
  .swiper-prev,
  .swiper-next {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}


button.close {
  font-family: Arial;
}

// FORM
form {
  .form-line {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;

    p {
      margin: 0;
    }

    .form-item {
      margin-top: 0;
    }
  }

  .form-item {
    width: 100%;
    margin: 10px 0;
    @include displayflex;
    @include flexnowrap;

    select, input, button, textarea {
      width: 100%;
      display: inline-block;
      border: 0;
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &.btn-ghost-white {
        border: 1px solid #fff;
        &:hover {
          border: 1px solid $light-blue;
        }
      }
    }
  }

  .icon-ok,
  .icon-wrong {
    &:before {
      margin-right: 10px;
    }
  }

  .hidden-input {
    input {
      display: none;
    }

    label {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // SELECT
 /* select {
    background: rgba(0, 0, 0, .1);
    padding: 10px 20px;
    font-size: #{18/$b-font-size}em;
    color: #fff;
    border-radius: 60px;
    border: 1px solid #fff;

    min-width: 220px;

    option {
      background: rgba(0, 0, 0, 0);
    }
  }

  .bootstrap-select {
    select {
      display: none;
    }

    .dropdown-toggle {
      background: rgba(0, 0, 0, .1);
      padding: 10px 20px;
      font-size: #{18/$b-font-size}em;
      color: #fff;
      border-radius: 60px;
      border: 1px solid #fff;

      .filter-option {
        width: 100%;
        text-align: left;
      }
    }

    &.open .btn-default.dropdown-toggle {
      background: #000;
      z-index: 99999;
      color: #fff;
    }

    div.dropdown-menu {
      max-height: initial !important;
      height: auto;
      background: rgba(0, 0, 0, .8);
      margin-top: -20px;
      width: 100%;
      min-height: 176px !important;
      overflow-y: auto !important;

      & > ul {
        top: 0;
      }
    }

    ul.dropdown-menu {
      width: 100%;
      background: none;
      padding: 30px 20px 10px;

      li {
        padding: 0;
        margin: 0;

        &:before {
          display: none;
        }

        .glyphicon {
          display: none;
        }

        &.selected a,
        a {
          padding: 5px 0;
          line-height: 1em;
          white-space: normal;
          color: #fff;
          background: none;

          span {
            background: none;
          }
        }

        span {
          white-space: normal;
        }

        &.selected,
        &:hover {
          background: none;

          a {
            background: none;
          }
        }
      }
    }

  }*/

  .form-buttons {
    width: 100%;
    display: inline-block;
    margin-top: 30px;
  }

  // FORM PADRAO COM FUNDO PRETO
  &.dark-form {
    label {
      @include transition(background, .3s, ease);
    }

    input:checked + label {
      background: $light-blue;
      border: 1px solid $light-blue;

      &:hover {
        color: #fff;
      }
    }
  }
}

.step-content {
  .block-title {
    margin-bottom: 80px;
    margin-top: 40px;
  }
}

// FORM DE COTAÇÃO
.form-steps {
  position: relative;

  &:after {
    content: '';
    display: block;

    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;

    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #666;
  }

  .steps {
    position: relative;
    z-index: 99;

    text-align: center;

    @include displayflex;
    @include flexnowrap;

    .step-item {
      width: 100%;
      padding: 0 10px 20px;
      cursor: pointer;
      position: relative;

      span {
        font-size: 1.1em;
        color: #fff;
        text-transform: uppercase;
      }

      &:after {
        content: '';
        display: block;

        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;

        width: 0;
        height: 8px;
        background: $light-blue;

        @include transition(width, .3s, ease-in);
      }

      &.active {
        &:after {
          width: 100%;
        }
      }

      &.firstborder:after {
        border-radius: 5px 0 0 5px;
      }
      &.lastborder:after {
        border-radius: 0 5px 5px 0;
      }

      &.firstborder.lastborder {
        &:after {
          border-radius: 5px;
        }
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .banner-sec.banner-default.cotacao-sec {
    padding-top: 0;
  }

  .step-content {
    text-align: left;
  }

  .form-steps {
    .steps {
      .step-item {
        span {
          display: none;
        }
      }
    }
  }

}