// ==========================================================================
// VARIABLES
// ==========================================================================

// BASE PARA FONTS
$b-font-size: 16;

// CORES
$light-color: #f6f6f6;
$light-color-hover: #ebebeb;

// AZUL
$light-blue: #4dcde4;
$main-blue: #017793;
$pastel-blue: #3b808e;
$dark-blue: #005768;
$darker-blue: #003a46;
$footer-bg: #04171d;

$menu-mobile: #004b65;

// VERDES
$light-green: #6cb64b;
$main-green: #4e9031;

// CINZAS
$light-gray: #b4b4b4;
$medium-gray: #787878;
$arrow-gray: #c7c7c7;

// FONTS
$font-family-sans-serif: 'Exo 2', sans-serif;
$font-family-serif: 'Exo 2', sans-serif;
$font-family-base: 'Exo 2', sans-serif;
$font-size-base: 16px;
$text-color: $main-blue;
$body-bg: #fff;



