label.error {
  display: none;
}

form .hidden-input input {
  visibility: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
  display: block;
}

.form-step {

  .nav-steps {
    position: relative;
    border: 0;
    display: inline-block;
    white-space: nowrap;

    &:after {
      content: '';
      height: 10px;
      display: block;
      background: #666;
      position: absolute;
      bottom: -2px;
      width: 100%;
      z-index: -1;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    li {
      float: none;
      display: inline-block;

      &:after {
        content: '';
        width: 100%;
        display: block;
        height: 8px;
        background: #666;
        border-radius: 5px;
        z-index: 1;
        position: relative;
      }

      &.answered,
      &.active {
        &:after {
          background: #4dcde4;
          z-index: 2;
        }

        a {
          background-color: transparent;
          color: #FFF;
          border: 0;
        }
      }

      &:not(:first-child) {
        margin-left: -9px;
      }

      a {
        border: 0;
        font-size: 1.1em;
        color: #fff;
        text-transform: uppercase;

        &:hover {
          background: none;
        }
      }
    }

    @media all and (max-width: 768px) {
      visibility: hidden;
    }
  }

  .form-line {
    margin-bottom: 15px;

    &[data-conditionaltarget] {
      display: none;
    }

    .form-item {
      display: block;

      .select2-container,
      textarea,
      input[type="date"],
      input[type="tel"],
      input[type="email"],
      input[type="text"] {
        width: auto;
        text-align: left;
      }

      input.error,
      input.error + label:not(.error),
      select.error + .select2-container {
        border: 1px solid red;
      }

      .select2-container {
        max-width: 319px;
        min-width: 236px;
      }

      &.date-input {
        .place-holder {
          display: none;
        }
      }

      @media(max-width: 768px) {
      &.date-input {
        margin: 0 auto;
        width: 240px;
        position: relative;

        input[type="date"] {
          width: 100%;

          & ~ .place-holder {
            display: block;
            position: absolute;
            top: 50%;
            margin-top: -12px;
            color: #FFF;
            background: #03252b;
            left: 6px;
            right: 6px;
            z-index: -1;
          }
        }

        &.filled {
          .place-holder {
            display: none !important;
          }
        }
      }
    }
    }

    .tip {
      display: block;
      color: #bfbfbf;
    }

    .change_value {
      background: none;
      display: block;
      color: #4dcde4;
      width: auto;
    }

    label.error {
      color: red;
    }

    @media all and (max-width: 991px) {
      text-align: center;

      p {
        margin: 0 0 4px;
      }

      .form-item {
        label {
          width: auto;
        }

      }
    }


  }

}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .loader-content {
    position: absolute;
    z-index: 1001;
    top: 50%;
    width: 100%;
    margin-top: -130px;
    color: #FFF;
    text-align: center;

    .loader-title {
      display: block;
    }

  }

  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 70px;
    height: 70px;
    margin: -35px 0 0 -35px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fffcff;

    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

    z-index: 1001;

    &:before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #ee9f3e;

      -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

    &:after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: #187490;

      -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
    }

  }

  .loader-section {
    position: fixed;
    top: 0;
    width: 51%;
    height: 100%;
    background: #1ba3cc;
    z-index: 1000;
    -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateX(0); /* IE 9 */
    transform: translateX(0); /* Firefox 16+, IE 10+, Opera */

    &.section-left {
      left: 0;
    }

    &.section-right {
      right: 0;
    }
  }
}

.loaded {

  #loader-wrapper {
    visibility: hidden;

    -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%); /* IE 9 */
    transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;

    .loader-content {
      -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      opacity: 0;
    }

    .loader-section {

      &.section-left {
        -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(-100%); /* IE 9 */
        transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      }

      &.section-right {
        -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: translateX(100%); /* IE 9 */
        transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

        -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
        transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
      }

    }
  }

  #loader {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

}

.no-js #loader-wrapper {
  display: none;
}

.no-js h1 {
  color: #222222;
}

body.cotacao {
  .cotacao-sec {
    background-color: #03252b;
  }

  @media all and (max-width: 768px) {
    .main {
      padding-top: 0;
    }
  }
}

.alert {
  color: yellow !important;
  padding-left: 0;
}

/* Animation */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

.modalBikeCotacao {
  z-index: 99999999;
  padding:0 !important;

  .modal-dialog{
    width: 100%;
    height: 100%;
    margin: 0;

    .modal-content{
      height: 100%;
      width: 100%;
      border-radius: 0;
      border: 0;

      .modal-header{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
      
      .modal-body{
        height: 100%;
        padding: 60px 0 67px;

        .bikeFrame{
          width: 100%;
          height: 100%;
        }
      }

      .modal-footer{
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
      }
    }
  }

}